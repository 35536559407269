
import Vue from 'vue'
import Vuex from 'vuex'
import axios from "../plugins/axios";
import api from "../api";
import { siteInfo } from "./site-info";

Vue.use(Vuex)

var refreshToken = sessionStorage.getItem("refreshToken");
var accessToken = sessionStorage.getItem("accessToken");

var payload = JSON.parse(atob(accessToken?.split('.')[1] || '') || "{}");
var scope = payload?.scope || [];
var levelCode = payload?.levelCode;


export default new Vuex.Store({
    state: {
        refreshToken,
        accessToken,
        payload,
        scope,
        levelCode,

        pickup: [],
    },
    mutations: {
        agreements(state, agreements){
            state.agreements = agreements;

            sessionStorage.setItem("agreements", JSON.stringify(agreements));
        },
        login(state, { refreshToken, accessToken  }) {
            state.refreshToken = refreshToken;
            state.accessToken = accessToken;
            
            state.payload = JSON.parse(atob(accessToken?.split('.')[1] || '') || "{}");
            state.scope = state.payload?.scope || [];
            state.levelCode = state.payload?.levelCode;

            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

            sessionStorage.setItem("refreshToken", refreshToken);
            sessionStorage.setItem('accessToken', accessToken);
        },

        logout(state) {
            state.refreshToken = undefined;
            state.accessToken = undefined;

            state.payload = {};
            state.scope = [];
            state.levelCode = null;

            axios.defaults.headers.common['Authorization'] = '';

            sessionStorage.removeItem("refreshToken");
            sessionStorage.removeItem("accessToken");
        },

        addToCart(state, _carts){
            state._carts = _carts;
            sessionStorage.setItem("_carts", JSON.stringify(_carts));
        },

        pickup(state, carts){
            state.pickup = carts;
        },

        checkout(state){
            state.pickup = [];
        },
    },
    actions: {
        agreements({ commit }, _terms){
            commit("agreements", _terms);
        },

        async login({ commit }, { username, password, auth }){
            var { refreshToken } = await api.auth.getRefreshToken({ username, password, auth });
            var { accessToken } = await api.auth.getAccessToken(refreshToken);

            commit("login", { refreshToken, accessToken } );
        },

        logout({ commit }) {
            commit("logout");
        },

        async refresh({ commit }, refreshToken){
            var { accessToken } = await api.auth.getAccessToken(refreshToken);

            commit("login", { refreshToken, accessToken });
        },

        addToCart({ commit }, _carts){
            commit("addToCart", _carts);
        },

        pickup({ commit }, { carts }){
            // Vue.$cookies.set("pickup", JSON.stringify({ 
            //     carts: carts.map(cart => {
            //         alert(cart.giftcard.mainGiftcard);
            //         return {
            //             ...cart,
            //             product: {
            //                 ...cart.product,
            //                 content: null,
            //                 mobileContent: null
            //             },
            //             giftcard: {
            //                 ...cart.giftcard,
            //                 content: null,
            //                 mobileContent: null
            //             }
            //         }
            //     })
            // }));

            // return Promise.resolve();
            commit("pickup", carts);
        },

        checkout({ commit }){
            commit("checkout");
        },
    },
    modules: {
        siteInfo,
    },
})
