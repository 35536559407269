import axios from "@/plugins/axios";
// import * as example from "./example";

let url = "/api/plugins/galaxia/billgate";

export const billgate = {
    // ...example,
    config: {
        get(){
            return axios.get(`${url}/config`).then(result => result.data);
        },
    },
    generate(data){
        return axios.post(`${url}/generate`, data).then(result => result.data);
    },
}
