import axios from "@/plugins/axios";

let url = "/api/plugins/wayup";

export const wayup = {
    pay: {
        post(data){
            return axios.post(`${url}/pay`, data).then(result => result.data);
        },
    }
}