import axios from "@/plugins/axios";
// import * as example from "./example";

let url = "/api/plugins/danal/t-pay";

export const tPay = {
    generate(data) {
        return axios.post(`${url}/generate`, data).then((result) => result.data);
    },
};
