import * as banners from "./banners";
import * as popups from "./popups";
import * as setting from "./setting";
import * as alarms from "./alarms";
import * as receptions from "./receptions";
import * as blacklists from "./blacklists";

export const common = {
    ...banners,
    ...popups,
    ...setting,
    ...alarms,
    ...receptions,
    ...blacklists
}
