import axios from "@/plugins/axios";

let url = "/api/v1/me/profile";

export const profile = {
    get(){
        return axios.get(`${url}`).then(result => result.data);
    },
    put(data){
        return axios.put(`${url}`, data).then(result => result.data);
    },
}
