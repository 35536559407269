import carts from "./carts";
import orders from "./orders";
import purchases from "./purchases";
import payments from "./payments";

export const gshop = {
    carts,
    orders,
    purchases,
    payments
}