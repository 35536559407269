import axios from "@/plugins/axios";

import * as auth from "./auth";
import * as console from "./console";
import * as v1 from "./v1";
import * as editor from "./editor";
import * as plugins from "./plugins";
import * as site from "./site";
// export { kakao } from "./kakao";
// export { naver } from "./naver";

export default {
    ...auth,
    ...console,
    ...v1,
    ...editor,
    ...plugins,
    ...site,
    getResource(filename){
        return axios({
            url: filename,
            method: 'GET',
            responseType: 'blob'
        }).then(result => {
                try{
                    return new File([result.data], filename);
                }
                catch(error){
                    var blob = new Blob([result.data]);
                    blob.name = filename;
                    return blob;
                }
            }
        );
    },
    resource: {
        get(filename, isShort = false) {
            return axios({
                url: filename,
                method: "GET",
                responseType: "blob",
            }).then((result) => {
                try {
                    return new File([result.data], isShort ? filename?.split("/")?.pop() : filename);
                } catch (error) {
                    var blob = new Blob([result.data]);
                    blob.name = filename;
                    return blob;
                }
            });
        },
        
        post(file, filePath = "/res"){

            let formData = (() => {
                let f = new FormData();
                f.append("file", file);
                return f;
            })();

            return axios.post("/res", formData, { 
                headers: { 
                    "Content-Type": "multipart/form-data",
                    "file-path": filePath
                }
            }).then(res => res.data);
        },
    },
};
