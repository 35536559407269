import * as orders from "./orders";
import * as giftcards from "./giftcards";
import * as pins from "./pins";
import * as categories from "./categories";
import * as purchases from "./purchases";
import * as setting from "./setting";
import * as collection from "./collection";
import * as messages from "./messages";

export const gshop = {
    ...orders,
    ...giftcards,
    ...pins,
    ...categories,
    ...purchases,
    ...setting,
    ...collection,
    ...messages
}
