export * from "./admins";
export * from "./members";
export * from "./levels";
export * from "./terms";

import * as points from "./points";

export const user = {
    ...points
}
