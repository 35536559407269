import axios from "@/plugins/axios";
// import * as example from "./example";

let url = "/api/v1/shop/categories";

export const categories = {
    // ...example,
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
}
