import axios from "@/plugins/axios";
// import * as example from "./example";

let url = "/api/v1/board/configs";

export const configs = {
    // ...example,
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    get(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
}
