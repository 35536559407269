import axios from "@/plugins/axios";

let url = "/api/console/common/setting/meta-image";

export const metaImage = {
    post({ metaImage }) {
        let formData = new FormData();
        formData.append("metaImage", metaImage);
        return axios.post(url, formData, { headers: { "Content-Type": "multipart/form-data" } }).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
};
