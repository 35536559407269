import axios from "@/plugins/axios";
// import * as example from "./example";

let url = "/api/plugins/danal/p-pay";

export const pPay = {
    generate(data) {
        return axios.post(`${url}/generate`, data).then((result) => result.data);
    },

    config: {
        get() {
            return axios.get(`${url}/config`).then((result) => result.data);
        },
    },
};
