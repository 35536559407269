import axios from "@/plugins/axios";

let url = "/api/plugins/onflatpay";

export const onplatpay = {
    get(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    post(data){
        return axios.post(url, data).then(result => result.data);
    },
}