import "@mdi/font/css/materialdesignicons.css"
import Vue from 'vue';
// import Vuetify from 'vuetify/lib';
import Vuetify from 'vuetify';
import colors from 'vuetify/lib/util/colors'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

/**
 * @type { import("vuetify/lib/services").Theme }
 */
export const basicTheme = {
    themes: {
        light: {
            primary: '#00a4e0',
            secondary: '#445163',
            accent: '#00C73C',
            // error: '#FF5252',
            // success: '#4CAF50',
            // warning: '#FFC107',
            anchor: '#333',

        },
        // dark: {
        //     primary: colors.purple.base,
        //     secondary: '#424242',
        //     accent: '#82B1FF',
        //     error: '#FF5252',
        //     info: '#2196F3',
        //     success: '#4CAF50',
        //     warning: '#FFC107',
        // }
    }
};

export const consoleTheme = {
    themes: {
        light: {
            primary: colors.blue.darken3,
            secondary: '#414c60',
            // accent: '#00C73C',
            accent: colors.blue.lighten1,
            // error: '#FF5252',
            "app-bar": '#2a3141',
            // info: colors.blue.darken4,
            content: "#EDF0F5",
            // success: '#4CAF50',
            // warning: '#FFC107',
            anchor: '#333',
        },
        // dark: {
        //     primary: colors.purple.base,
        //     secondary: '#424242',
        //     accent: '#82B1FF',
        //     error: '#FF5252',
        //     info: '#2196F3',
        //     success: '#4CAF50',
        //     warning: '#FFC107',
        // }
    }
};

/**
 * 
 * @param {(basicTheme|consoleTheme)} theme 
 * @returns 
 */
export default function(theme){
    return new Vuetify({
        lang: {
            locales: { ko: require("vuetify/lib/locale/ko") },
            current: 'ko',
        },
        theme: theme || basicTheme
    });
} 