import axios from "@/plugins/axios";
// import * as example from "./example";

let url = "/api/v1/common/banners";

export const banners = {
    // ...example,
    get(data){
        return axios.get(`${url}/${data.code}`).then(result => result.data);
    },
}
