import api from "@/api";

export const siteInfo = {
    namespaced: true,
    state: {
        projectName: undefined,
        projectNameKo: undefined,
        serviceName: undefined,
        serviceNameKo: undefined,
        tel: undefined,
        hostname: undefined,
        gateway: undefined,
        memberPayable: undefined,
        nonmemberPayable: undefined,
        paymentGateway: undefined,
        paymentMethods: [],
    },
    mutations: {
        setSiteInfo(state, siteInfo) {
            for(let key of Object.keys(siteInfo)){
                state[key] = siteInfo[key];
            }
        },
    },
    actions: {
        async getSiteInfo({ commit }) {
            const { siteInfo } = await api.site.info.get();
            commit("setSiteInfo", siteInfo);
        },
    },
};
