import axios from "@/plugins/axios";

let url = "/api/v1/me/points";

export const points = {
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    gift: {
        post(data){
            return axios.post(`${url}/gift`, data).then(result => result.data);
        }
    }
}
