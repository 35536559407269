import * as orders from "./orders";
import * as giftcards from "./giftcards";
import * as setting from "./setting";
import * as collection from "./collection";
import * as pointItems from "./point-items";

export const gshop = {
    ...orders,
    ...giftcards,
    ...setting,
    ...collection,
    ...pointItems,
}
