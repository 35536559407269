import axios from "@/plugins/axios";
// import * as example from "./example";

let url = "/api/plugins/onnetworks/xplus";

export const xplus = {
    // ...example,
    config: {
        get(){
            return axios.get(`${url}/config`).then(result => result.data);
        },
    }
}
