import axios from "@/plugins/axios";

let url = "/api/plugins/korpay";

export const korpay = {
    config: {
        get(){
            return axios.get(`${url}/config`).then(result => result.data);
        },
    },
    generate(data){
        return axios.post(`${url}/generate`, data).then(result => result.data);
    },
}