import axios from "@/plugins/axios";

let url = "/api/console/gshop/giftcards";

export const giftcards = {
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    get(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    post(data){
        return axios.post(url, data).then(result => result.data);
    },
    put(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    delete(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    },
    thumb: {
        post(_giftcard, thumb){
            let formData = new FormData();
            formData.append("thumb", thumb);
            return axios.post(`${url}/${_giftcard}/thumb`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(result => result.data);
        }
    },
    images: {
        post(_giftcard, image, index){
            let formData = new FormData();
            formData.append("image", image);
            return axios.post(`${url}/${_giftcard}/images/${index}`, formData, { headers: { "Content-Type": "multipart/form-data"} }).then(result => result.data);
        }
    },
    pins: {
        gets(_id, data){
            return axios.get(`${url}/${_id}/pins`, data).then(result => result.data);
        },
        
        summaries: {
            gets(_id, data){
                return axios.get(`${url}/${_id}/pins/summaries`, data).then(result => result.data);
            }
        }
    }
}
