import axios from "@/plugins/axios";

let url = "/api/plugins/nanuri/pin";

export const pin = {
    cp: {
        get(){
            return axios.get(`${url}/cp`).then(result => result.data);
        }
    },
}
