import axios from "@/plugins/axios";

let url = "/api/v1/gshop/orders";

export const orders = {
    get(data){
        return axios.get(`${url}/${data._id || data.orderNo}`).then(result => result.data);
    },
    post(data){
        return axios.post(url, data).then(result => result.data);
    },
    pay: {
        post(data){
            return axios.post(`${url}/pay`, data).then(result => result.data);
        }
    }
}
