import axios from "@/plugins/axios";

let url = "/api/plugins/paynb";

export const paynb = {
    // config(data){
    //     return axios.get(`${url}/${data._id}`).then(result => result.data);
    // },
    request(data){
        return axios.post(`${url}/request`, data).then(result => result.data);
    },
}