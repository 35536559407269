import axios from "@/plugins/axios";
// import * as example from "./example";

let url = "/api/site";

export const site = {
    // ...example,
    info: {
        get(data){
            return axios.get(`${url}/info`, data).then(result => result.data);
        },
    }
}
