import axios from "@/plugins/axios";

let url = "/api/plugins/hecto-financial/settlebank";

export const settlebank = {
    config(data){
        return axios.get(`${url}/config`, data).then(result => result.data);
    },
    generate(data){
        return axios.post(`${url}/generate`, data).then(result => result.data);
    },
}