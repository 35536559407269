import axios from "@/plugins/axios";
import CryptoAES from '@/plugins/crypto-aes';

export const auth = {
    getRefreshToken({ username, password, auth }){
        var basicToken = "Basic " + CryptoAES.encrypt( new Buffer(`${username}:${password}:${auth}`).toString('base64') );

        return axios.get(`/api/auth/refreshToken`, {
            headers: { "Authorization": basicToken }
        }).then(result => result.data);
    },
    getAccessToken(refreshToken){
        var bearerToken = `Bearer ${refreshToken}`;

        return axios.get(`/api/auth/accessToken`, {
            headers: { "Authorization": bearerToken }
        }).then(result => result.data);
    },
    config(){
        return axios.get(`/api/auth/config`).then(result => result.data);
    }
}
