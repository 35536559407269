import axios from "@/plugins/axios";
// import * as example from "./example";

let url = "/api/v1/members";

export const members = {
    // ...example,
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    post(data){
        return axios.post(url, data).then(result => result.data);
    },
    repair(data){
        return axios.post(`${url}/repair`, data).then(result => result.data);
    },
    exists(data){
        return axios.get(`${url}/exists`, data).then(result => result.data);
    },
    verify(data){
        return axios.put(`${url}/verify`, data).then(result => result.data);
    }
}
