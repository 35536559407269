import * as common from "./common";
import * as center from "./center";
import * as theme from "./theme";
import * as board from "./board";
import * as user from "./user";
import * as gshop from "./gshop";
import * as shop from "./shop";
import * as me from "./me";

export const v1 = {
    ...common,
    ...center,
    ...theme,
    ...board,
    ...user,

    ...gshop,
    ...shop,
    ...me,
}