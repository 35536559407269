import axios from "@/plugins/axios";
import * as center from "./center";
import * as profile from "./profile";
import * as shop from "./shop";
import * as gshop from "./gshop";
import * as points from "./points";
import * as gifts from "./gifts";

export const me = {
    ...profile,
    ...center,
    ...shop,
    ...gshop,
    ...points,
    ...gifts,
    
    withdraw(){
        return axios.put(`/api/v1/me/withdraw`).then(result => result.data);
    }
}
