import axios from "@/plugins/axios";
import { metaImage } from "./metaImage";

let url = "/api/console/common/setting";

export const setting = {
    metaImage,

    get(data) {
        return axios.get(`${url}`).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}`, data).then((result) => result.data);
    },
};
