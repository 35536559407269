import axios from "@/plugins/axios";

let url = "/api/plugins/mainpay";

export const mainpay = {
	ready(data) {
		return axios.post(`${url}/ready`, data).then(result => result.data);
	},
    generate(data){
        return axios.post(`${url}/generate`, data).then(result => result.data);
    },
}