import CryptoJS from "crypto-js";

const encryptor = CryptoJS.enc.Utf8;

const CRYPTO_SECRET_KEY = process.env.VUE_APP_CRYPTO_SECRET_KEY;
const CRYPTO_SECRET_IV = process.env.VUE_APP_CRYPTO_SECRET_IV;

export const encrypt = (planText) => {
    return CryptoJS.AES.encrypt(planText, encryptor.parse(CRYPTO_SECRET_KEY), { iv: encryptor.parse(CRYPTO_SECRET_IV) }).toString();
}
export const decrypt = (cyperText) => {
    return CryptoJS.AES.decrypt(cyperText, encryptor.parse(CRYPTO_SECRET_KEY), { iv: encryptor.parse(CRYPTO_SECRET_IV) }).toString(encryptor);
}

export default {
    encrypt,
    decrypt
}
