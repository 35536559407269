import * as common from "./common";
import * as user from "./user";
import * as center from "./center";

import * as board from "./board";
import * as form from "./form";

import * as gshop from "./gshop";
import * as themes from "./themes";

export const console = {
    ...common,
    ...user,
    ...center,

    ...board,
    ...form,

    ...gshop,
    ...themes,
};