import Vue from "vue";
import VueRouter from "vue-router";
import axios from "../plugins/axios";

Vue.use(VueRouter);

const routes = [
    ////////////////////////////////////////////////////////////////////////////
    // 사용자
    ////////////////////////////////////////////////////////////////////////////
    {
        path: "/",
        component: () => import("../pages/client/Main.vue"),
        props: true,
    },
    {
        path: "/login",
        component: () => import("../pages/client/Login.vue"),
        props: true,
    },
    {
        path: "/about",
        component: () => import("../pages/client/About.vue"),
        props: true,
    },
    {
        path: "/contact",
        component: () => import("../pages/client/Contact.vue"),
        props: true,
    },
    {
        path: "/join",
        component: () => import("../pages/client/Join.vue"),
        props: true,
    },
    {
        path: "/charge/:_order",
        component: () => import("../pages/client/Charge.vue"),
        props: true,
    },
    {
        path: "/board/question",
        component: () => import("../pages/client/board/Question.vue"),
        props: true,
    },
    {
        path: "/board/question/create",
        component: () => import("../pages/client/board/Question.vue"),
        props: true,
    },
    {
        path: "/board/question/:_board",
        component: () => import("../pages/client/board/Question.vue"),
        props: true,
    },
    {
        path: "/board/question/:_board/edit",
        component: () => import("../pages/client/board/Question.vue"),
        props: true,
    },
    {
        path: "/terms/tos",
        component: () => import("../pages/client/terms/TermsOfService.vue"),
        props: true,
    },
    {
        path: "/terms/privacy",
        component: () => import("../pages/client/terms/Privacy.vue"),
        props: true,
    },
    {
        path: "/center/notifications",
        component: () => import("../pages/client/center/Notification.vue"),
        props: true,
    },
    {
        path: "/center/notifications/:_notification",
        component: () => import("../pages/client/center/Notification.vue"),
        props: true,
    },
    {
        path: "/center/faqs",
        component: () => import("../pages/client/center/Faq.vue"),
        props: true,
    },
    {
        path: "/center/questions",
        component: () => import("../pages/client/center/Question.vue"),
        props: true,
    },
    {
        path: "/center/questions/:_question",
        component: () => import("../pages/client/center/Question.vue"),
        props: true,
    },

    {
        path: "/member/profile",
        component: () => import("../pages/client/member/Profile.vue"),
        props: true,
    },
    {
        path: "/member/idpw",
        component: () => import("../pages/client/member/IdPw.vue"),
        props: true,
    },
    {
        path: "/member/gshop/orders",
        component: () => import("../pages/client/member/gshop/Order.vue"),
        props: true,
    },
    {
        path: "/member/point/points",
        component: () => import("../pages/client/member/point/points.vue"),
        props: true,
    },
    {
        path: "/member/point/charge",
        component: () => import("../pages/client/member/point/charge.vue"),
        props: true,
    },
    {
        path: "/member/point/point-gift",
        component: () => import("../pages/client/member/point/point-gift.vue"),
        props: true,
    },
    {
        path: "/member/point/gifts",
        component: () => import("../pages/client/member/point/gifts.vue"),
        props: true,
    },
    {
        path: "/member/payments",
        component: () => import("../pages/client/member/Payment.vue"),
        props: true,
    },
    {
        path: "/member/points",
        component: () => import("../pages/client/member/Point.vue"),
        props: true,
    },
    {
        path: "/gshop/giftcards",
        component: () => import("../pages/client/gshop/GiftcardList.vue"),
        props: true,
    },
    {
        path: "/gshop/giftcards/:_giftcard",
        component: () => import("../pages/client/gshop/GiftcardView.vue"),
        props: true,
    },
    {
        path: "/gshop/point-mall",
        component: () => import("../pages/client/gshop/point-mall.vue"),
        props: true,
    },
    {
        path: "/gshop/company-mall",
        component: () => import("../pages/client/gshop/company-mall.vue"),
        props: true,
    },
    {
        path: "/gshop/order",
        component: () => import("../pages/client/gshop/Order.vue"),
        props: true,
    },
    {
        path: "/gshop/result",
        component: () => import("../pages/client/gshop/Result.vue"),
        props: true,
    },
    {
        path: "/console/gshop/setting/order-names",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/gshop/setting/order-names/index.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // 플러그인
    ////////////////////////////////////////////////////////////////////////////
    {
        path: "/certification",
        component: () => import("../pages/client/Certification.vue"),
        props: true,
    },
    {
        path: "/plugins/kcp/cert/request",
        component: () => import("../pages/plugins/kcp/KCPCertRequest.vue"),
        props: true,
    },
    {
        path: "/plugins/kcp/cert/response",
        component: () => import("../pages/plugins/kcp/KCPCertResponse.vue"),
        props: true,
    },
    {
        path: "/plugins/galaxia/billgate/result",
        component: () => import("../pages/plugins/galaxia/billgate/BillgateResult.vue"),
        props: true,
    },
    {
        path: "/plugins/coampay/request",
        component: () => import("../pages/plugins/coampay/CoampayRequest.vue"),
        props: true,
    },
    {
        path: "/plugins/coampay/result",
        component: () => import("../pages/plugins/coampay/CoampayResult.vue"),
        props: true,
    },
    {
        path: "/plugins/reap/reappay/request",
        component: () => import("../pages/plugins/reap/ReappayRequest.vue"),
        props: true,
    },
    {
        path: "/plugins/reap/reappay/response",
        component: () => import("../pages/plugins/reap/ReappayResponse.vue"),
        props: true,
    },
    {
        path: "/plugins/wizzpay/request",
        component: () => import("../pages/plugins/wizzpay/WizzpayRequest.vue"),
        props: true,
    },
    {
        path: "/plugins/wizzpay/response",
        component: () => import("../pages/plugins/wizzpay/WizzpayResponse.vue"),
        props: true,
    },
    {
        path: "/plugins/korpay/request",
        component: () => import("../pages/plugins/korpay/request.vue"),
        props: true,
    },
    {
        path: "/plugins/korpay/result",
        component: () => import("../pages/plugins/korpay/result.vue"),
    },
    {
        path: "/plugins/paytus/request",
        component: () => import("../pages/plugins/paytus/request.vue"),
        props: true,
    },
    {
        path: "/plugins/paytus/result",
        component: () => import("../pages/plugins/paytus/result.vue"),
    },
    {
        path: "/plugins/danal/p-pay/result",
        component: () => import("../pages/plugins/danal/p-pay/PPayResult.vue"),
        props: true,
    },
    {
        path: "/plugins/danal/t-pay/result",
        component: () => import("../pages/plugins/danal/t-pay/TPayResult.vue"),
        props: true,
    },
    {
        path: "/plugins/onflat/request",
        component: () => import("../pages/plugins/onflatpay/request.vue"),
        // props: true
    },
    {
        path: "/plugins/onflat/result",
        component: () => import("../pages/plugins/onflatpay/result.vue"),
        // props: true
    },
    {
        path: "/plugins/kg/allatpay/request",
        component: () => import("../pages/plugins/kg/allatpay/request.vue"),
        // props: true
    },
    {
        path: "/plugins/kg/allatpay/response",
        component: () => import("../pages/plugins/kg/allatpay/response.vue"),
        // props: true
    },
    {
        path: "/plugins/hecto-financial/settlebank/request",
        component: () => import("../pages/plugins/hecto-financial/settlebank/request.vue"),
        // props: true
    },
    {
        path: "/plugins/hecto-financial/settlebank/result",
        component: () => import("../pages/plugins/hecto-financial/settlebank/result.vue"),
        // props: true
    },
    {
        path: "/plugins/mainpay/result",
        component: () => import("../pages/plugins/mainpay/result.vue"),
    },
    {
        path: "/plugins/smartro/request",
        component: () => import("../pages/plugins/smartro/request.vue"),
    },
    {
        path: "/plugins/smartro/result",
        component: () => import("../pages/plugins/smartro/result.vue"),
    },
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        path: "/console",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Main.vue"),
        props: true,
    },
    {
        path: "/console/dashboard",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Dashboard.vue"),
        props: true,
    },

    {
        path: "/console/admins",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/admin/AdminList.vue"),
        props: true,
    },
    {
        path: "/console/admins/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/admin/AdminView.vue"),
        props: true,
    },
    {
        path: "/console/admins/:_admin",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/admin/AdminView.vue"),
        props: true,
    },
    {
        path: "/console/members",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/MemberList.vue"),
        props: true,
    },
    {
        path: "/console/members/:_member",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/MemberView.vue"),
        props: true,
    },
    {
        path: "/console/member/withdrawals",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/WithdrawalList.vue"),
        props: true,
    },
    {
        path: "/console/member/points",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/PointList.vue"),
        props: true,
    },
    {
        path: "/console/:type/levels",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/LevelList.vue"),
        props: true,
    },
    {
        path: "/console/:type/levels/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/LevelView.vue"),
        props: true,
    },
    {
        path: "/console/:type/levels/:_level",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/LevelView.vue"),
        props: true,
    },
    {
        path: "/console/terms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/TermsList.vue"),
        props: true,
    },
    {
        path: "/console/terms/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/TermsView.vue"),
        props: true,
    },
    {
        path: "/console/terms/:_terms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/TermsView.vue"),
        props: true,
    },
    {
        path: "/console/board/configs",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/ConfigList.vue"),
        props: true,
    },
    {
        path: "/console/board/configs/:_config",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/ConfigView.vue"),
        props: true,
    },
    {
        path: "/console/board/question",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/question/BoardList.vue"),
        props: true,
    },
    {
        path: "/console/board/question/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/question/BoardView.vue"),
        props: true,
    },
    {
        path: "/console/board/question/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/question/BoardView.vue"),
        props: true,
    },
    {
        path: "/console/form/configs",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/ConfigList.vue"),
        props: true,
    },
    {
        path: "/console/form/configs/:_config",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/ConfigView.vue"),
        props: true,
    },
    {
        path: "/console/forms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormList.vue"),
        props: true,
    },
    {
        path: "/console/forms/:_form",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormView.vue"),
        props: true,
    },
    {
        path: "/console/gshop/giftcards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/gshop/giftcard/GiftcardList.vue"),
        props: true,
    },
    {
        path: "/console/gshop/giftcards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/gshop/giftcard/GiftcardView.vue"),
        props: true,
    },
    {
        path: "/console/gshop/giftcards/:_giftcard",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/gshop/giftcard/GiftcardView.vue"),
        props: true,
    },
    {
        path: "/console/gshop/giftcards/:_giftcard/stock",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/gshop/giftcard/StockView.vue"),
        props: true,
    },
    {
        path: "/console/gshop/categories",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/gshop/category/CategoryList.vue"),
        props: true,
    },
    {
        path: "/console/gshop/purchases/order",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/gshop/purchases/Order.vue"),
        props: true,
    },
    {
        path: "/console/gshop/purchases/issue",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/gshop/purchases/Issue.vue"),
        props: true,
    },
    {
        path: "/console/gshop/purchases/cancel",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/gshop/purchases/Cancel.vue"),
        props: true,
    },
    {
        path: "/console/gshop/purchases/sales",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/gshop/purchases/Sales.vue"),
        props: true,
    },
    {
        path: "/console/gshop/setting/informations",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/gshop/setting/informations/index.vue"),
        props: true,
    },
    {
        path: "/console/gshop/messages",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/gshop/messages/index.vue"),
        props: true,
    },
    {
        path: "/console/gshop/messages/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/gshop/messages/[_id].vue"),
        props: true,
    },
    {
        path: "/console/gshop/messages/:_id",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/gshop/messages/[_id].vue"),
        props: true,
    },

    {
        path: "/console/center/notifications",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/notification/NotificationList.vue"),
        props: true,
    },
    {
        path: "/console/center/notifications/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/notification/NotificationView.vue"),
        props: true,
    },
    {
        path: "/console/center/notifications/:_notification",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/notification/NotificationView.vue"),
        props: true,
    },
    {
        path: "/console/center/faqs",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/faq/FaqList.vue"),
        props: true,
    },
    {
        path: "/console/center/faqs/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/faq/FaqView.vue"),
        props: true,
    },
    {
        path: "/console/center/faqs/:_faq",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/faq/FaqView.vue"),
        props: true,
    },
    {
        path: "/console/center/questions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/question/QuestionList.vue"),
        props: true,
    },
    {
        path: "/console/center/questions/:_question",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/question/QuestionView.vue"),
        props: true,
    },
    {
        path: "/console/common/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/common/setting/SettingView.vue"),
        props: true,
    },
    {
        path: "/console/common/popups",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/common/popup/PopupList.vue"),
        props: true,
    },
    {
        path: "/console/banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/common/banner/BannerList.vue"),
        props: true,
    },
    {
        path: "/console/banners/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/common/banner/BannerView.vue"),
        props: true,
    },
    {
        path: "/console/banners/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/common/banner/BannerView.vue"),
        props: true,
    },
    {
        path: "/console/footer",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/footer/FooterView.vue"),
        props: true,
    },
    {
        path: "/console/meta",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/meta/MetaView.vue"),
        props: true,
    },
    {
        path: "/console/themes",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/theme/ThemeList.vue"),
        props: true,
    },
    {
        path: "/console/themes/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/theme/ThemeView.vue"),
        props: true,
    },
    {
        path: "/console/themes/:_theme",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/theme/ThemeView.vue"),
        props: true,
    },
    {
        path: "/console/receptions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/common/reception/ReceptionList.vue"),
        props: true,
    },
    {
        path: "/console/receptions/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/common/reception/ReceptionView.vue"),
        props: true,
    },
    {
        path: "/console/receptions/:_reception",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/common/reception/ReceptionView.vue"),
        props: true,
    },
    {
        path: "/console/collection",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/gshop/collection/CollectionList.vue"),
        props: true,
    },
    {
        path: "/console/common/blacklists",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/common/blacklists/index.vue"),
        props: true,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    // 스크롤 항상 위로 이동
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    let accessToken = sessionStorage.getItem("accessToken");

    axios.defaults.headers.common["Authorization"] = accessToken ? `Bearer ${accessToken}` : "";

    next();
});

export default router;
