import axios from "@/plugins/axios";
// import * as example from "./example";

let url = "/api/v1/common/setting";

export const setting = {
    // ...example,
    get(data){
        return axios.get(url).then(result => result.data);
    },
}
