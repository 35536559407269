import axios from "@/plugins/axios";

let url = "/api/plugins/paytus";

export const paytus = {
    config: {
        get(){
            return axios.get(`${url}/config`).then(result => result.data);
        },
    },
    generate(data){
        return axios.post(`${url}/generate`, data).then(result => result.data);
    },
    response(data){
        return axios.post(`${url}/response`, data).then(result => result.data)
    }
}