import axios from "@/plugins/axios";

let url = "/api/v1/me/gshop/orders";

export default {
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    get(data){
        return axios.get(`${url}/${data._id || data.orderNo}`).then(result => result.data);
    },
    resend(data){
        return axios.post(`${url}/${data._id}/resend`).then(result => result.data);
    }
}
