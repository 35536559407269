import axios from "@/plugins/axios";

let url = "/api/v1/me/shop/purchases";

export default {
    get(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    put(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
}
