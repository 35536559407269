import axios from "@/plugins/axios";

let url = "/editor";

export const editor = {
    postPhoto(data){
        var formData = new FormData(); formData.append("photo", file);
        var headers = { "Content-type": "multipart/formdata" };
        return axios.post(url, formData, { headers }).then(result => result.data);
    },
}
